@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);
@import "bootstrap/bootstrap";
@import "theme/app";

/* ADDONS */

// HEADERS COLOR TAB

.header {
  min-height: 50px;
  .nav-tabs>li.active {
    a {
      background-color: @body-bg;
      color: @text-color;
      &:hover {
        background-color: @body-bg;
        color: @text-color;
      }
    }
  }
  .nav-tabs {
    border: none;
  }
  .nav-tabs>li {
    a {
      border-radius: 0;
      border: none !important;
      line-height: 20px;
      padding-bottom: 15px;
      padding-top: 15px;
    }
  }
}

.nav-tabs>li>a {
  border-color: transparent;
  border-radius: 0;
  color: rgba(98, 98, 98, 0.7);
  font-weight: 400;
  margin-right: 0;
  min-width: 70px;
  padding: 15px 20px;
  text-transform: uppercase;
  &:hover {
    background: transparent;
    border-color: transparent;
  }
  &:focus {
    background: transparent;
    border-color: transparent;
  }
}

.bg-warning {
  .nav {
    li>a {
      &:hover {
        background-color: darken(@brand-warning, 10%);
      }
    }
  }
}

